<template>
  <iframe ref="iframe" class="policy"></iframe>
</template>

<script>
import { getPolicy } from '@/api/response'
import { setLang } from '@/utils/lang';
import { setRtl } from '@/utils/survey';
export default {
  name: 'Policy',
  props: {
    lang: {
      type: String
    }
  },
  data () {
    return {
      policy: ''
    }
  },
  methods: {
    async getPolicy () {
      const { data: { success, data } } = await getPolicy(this.lang)
      if (success) this.policy = data
    },
  },
  async mounted () {
    await this.getPolicy()
    const frame = this.$refs.iframe
    frame.contentDocument.documentElement.innerHTML = this.policy
    frame.contentDocument.documentElement.style['overflow-x'] = 'hidden'
    frame.contentWindow.confirmAgreement = () => {
      this.$emit('confirmed')
    }
    this.$emit('ready', true)
  },
  async created() {
    const { lang } = this.$route.query;
    this.$i18n.locale = lang;
    setLang(lang);
    if (['ar', 'fa', 'ur'].includes(lang)) setRtl(true);
  },
}
</script>

<style scoped>
.policy {
  z-index: 10;
  width: 100vw;
  height: 100vh;
	height: -webkit-fill-available;
}
</style>
